// import logo from './logo.svg';
// import './App.css';

import Navigation from "./pages/Navigation";

function App() {
  return (
    <>
        <Navigation />
    </>
  );
}

export default App;
