import { useEffect } from 'react';
import Form from './Form';
import Map from './Map';
import './Contacto.css';

export default function Contacto() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="contact">
                <div className="top" />
                <div className="info">
                    <div className="container py-lg-5">
                        <div className="row py-5">
                            <div className="col-lg-6">
                                <h1 className="fs-1 txt-blue1 text-lg-start text-center mb-4">
                                    ¡Contáctanos!<br/>
                                    Con gusto te atenderemos
                                </h1>
                                <Form />
                            </div> {/* col */}
                            <div className="col-lg-6 mt-lg-0 mt-4">
                                <Map />
                                <div className="row">
                                    <div className="col-md-4 my-4">
                                        <ul className="fa-ul fs-6 light">
                                            <li className="mb-2 txt-blue2 regular">
                                                Salamanca
                                            </li>
                                            <li className="mb-2 txt-blue2">
                                                <span className="fa-li"><i className="fas fa-phone-alt txt-blue1"></i></span>
                                                <a href="tel:4646487411" className="txt-blue2">(464) 648 74 11</a>
                                            </li>
                                        </ul>
                                        <ul className="fa-ul fs-6 light">
                                            <li className="mb-2 txt-blue2 regular">
                                                San Luis Potosí
                                            </li>
                                            <li className="mb-2 txt-blue2">
                                                <span className="fa-li"><i className="fas fa-phone-alt txt-blue1"></i></span>
                                                <a href="tel:4446256211" className="txt-blue2">(444) 625 62 11</a>
                                            </li>
                                        </ul>
                                    </div> {/* col */}
                                    <div className="col-md-8 my-md-4 mb-4">
                                        <ul className="fa-ul fs-6 light">
                                            <li className="mb-2 txt-blue2 regular">
                                                Correos
                                            </li>
                                            <li className="mb-2 txt-blue2">
                                                <span className="fa-li"><i className="fas fa-envelope txt-blue1"></i></span>
                                                <a href="mailto:ventas@hielosecodelbajio.com.mx" target="_blank" rel="noopener noreferrer" className="txt-blue2">ventas@hielosecodelbajio.com.mx</a>
                                            </li>
                                            <li className="mb-2 txt-blue2">
                                                <span className="fa-li"><i className="fas fa-envelope txt-blue1"></i></span>
                                                <a href="mailto:contacto@hielosecodelbajio.com.mx" target="_blank" rel="noopener noreferrer" className="txt-blue2">contacto@hielosecodelbajio.com.mx</a>
                                            </li>
                                        </ul>
                                    </div> {/* col */}
                                </div> {/* row */}
                            </div> {/* col */}
                        </div> {/* row */}
                    </div> {/* cont */}
                </div> {/* top */}
            </div>
        </>
    )
}