import { useState, useEffect } from 'react';
import './pozos.min.css';
import dotsMarino from '../assets/images/pozos/DotsMarino.png';
import dotsCielo from '../assets/images/pozos/DotsCielo.png';
import hielos from '../assets/images/pozos/Hielos.png';
import hombre from '../assets/images/pozos/Hombre.png';
import pozo from '../assets/images/pozos/Pozo.png';

export default function LimpiezaCriogenica() {
    const [yPos, setYPos] = useState(0)
    const [yPos2, setYPos2] = useState(0)
    const [yPos3, setYPos3] = useState(0)
    useEffect(() => {
        const handleScroll = () => {
            let $wTop = window.scrollY
            const im1 = document.querySelector('#pozo'),
            im2 = document.querySelector('#worker'),
            im3 = document.querySelector('#hielos')
            setYPos($wTop * .2)
            setYPos2($wTop * .1)
            setYPos3($wTop * .03)
            im1.style.transform = "translateY(" + yPos + "px)"
            im2.style.transform = "translateY(" + yPos2 + "px)"
            im3.style.transform = "translateY(" + yPos3 + "px)"
        }
        window.removeEventListener('scroll', handleScroll)
        window.addEventListener('scroll', handleScroll, {passive: true})

        return () => window.removeEventListener('scroll', handleScroll)
    }, [yPos, yPos2, yPos3])

    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById('pozo').style.transform = 'translateY(0px)'
        document.getElementById('worker').style.transform = 'translateY(0px)'
        document.getElementById('hielos').style.transform = 'translateY(0px)'
    }, [])

    return (
        <section className="pozos">
            <article className="top">
                <div className="container py-md-5">
                    <div className="row py-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-3 txt-white bold fnt-T lh-1 mb-4">
                                DESTAPE DE POZOS PROFUNDOS <br/>
                                <span className="txt-blue5 bold fnt-T">CON HIELO SECO</span>
                            </h1>
                        </div>
                        <div className="col-lg-6 h-100">
                            <img src={pozo} alt="pozo" className='img-fluid' id='pozo' />
                        </div>
                    </div>
                </div>
            </article>
            <article className="description">
                <div className="container py-lg-5">
                    <div className="row py-5">
                        <div className="col-lg-6 col-md-7">
                            <p className="txt-blue1 fs-5">
                                El hielo seco es dióxido de carbono (CO2) en estado sólido que a presión atmosférica se encuentra a una temperatura de -78.5ºC.
                            </p>
                            <p className="txt-blue1 fs-5">
                                La ventaja principal de dicho procedimiento es que no requiere costes elevados y el equipo es reducido comparado con cualquier otro método.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-5 align-self-end">
                            <img src={dotsMarino} alt="dots" className='img-fluid dots' />
                        </div>
                    </div>
                </div>
            </article>
            <article className="process">
                <div className="container py-lg-5">
                    <div className="row py-5">
                        <div className="col-lg-6 box--gray">
                            <h1 className="display-4 fnt-T txt-blue1 bold text-center mb-4">
                                ¿CUÁL ES EL PROCESO?
                            </h1>
                            <img src={hielos} alt="hielos" className='img-fluid mx-auto d-block' id='hielos' />
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <p className='fs-5 txt-blue1 regular'>
                                Al introducir hielo seco en el pozo a destapar se produce un efecto de sublimación y explosión: Al entrar en contacto con la superficie, <strong>el hielo seco se calienta y comienza a sublimar, es decir que pasa inmediatamente del estado sólido al estado gaseoso (CO2),</strong> penetrando entre las grietas y por debajo de la capa residual.
                            </p>
                            <p className='fs-5 txt-blue1 regular'>
                                Al sublimarse, el gas CO2 se expande y aumenta su volumen, <strong>produciendo una explosión que levanta y desprende la capa residual de la superficie oculta.</strong>
                            </p>
                            <p className='fs-5 txt-blue1 regular'>
                                Con este método se pueden limpiar o desatascar pozos o tuberías muy profundas.
                            </p>
                           
                        </div>
                    </div>
                </div>
            </article>
            <article className="video">
                <div className="container py-lg-5">
                    <div className="row py-5 justify-content-center">
                        <div className="col-12">
                            <h1 className="fs-1 fnt-T txt-blue1 bold text-center mb-4">
                                VE EL SIGUIENTE VIDEO <span className="txt-blue5 bold">Y CONOCE A FONDO EL PROCESO</span>
                            </h1>
                        </div>
                        <div className="col-lg-10">
                            <div className="ratio ratio-16x9">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/ydp32DPU3eY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <article className="services">
                <div className="container py-md-5">
                    <div className="row py-5 justify-content-center">
                        <div className="col-lg-5 info">
                            <h1 className="fs-1 fnt-T txt-white bold text-lg-start text-center">
                                ¿NECESITAS DESTAPAR <span className="txt-blue5 bold">POZOS O TUBERÍAS PROFUNDAS?</span>
                            </h1>
                            <p className="fs-5 txt-white regular">
                                Somos tu mejor aliado para llevar a cabo este procedimiento, tenemos la experiencia y capacitación requeridas.
                            </p>
                        </div>
                        <div className="col-lg-5">
                            <img src={dotsCielo} alt="dots" className='w-75 mx-auto d-block' id='dotsCielo' />
                            <img src={hombre} alt="hombre" className='img-fluid' id='worker' />
                        </div>
                        <div className="col-lg-10 box--white mt-5">
                            <div className="row py-4 align-items-center justify-content-center">
                                <div className="col-md-4 mb-md-0 mb-3">
                                    <a href="tel:4446256211" target="_blank" rel="noopener noreferrer" className='fs-5 txt-blue1 regular d-block'>
                                        <i className="fa-solid fa-phone"></i> Tel. (444) 625 62 11
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-7">
                                    <a href="mailto:ventas@hielosecodelbajio.com.mx" target="_blank" rel="noopener noreferrer" className='fs-5 txt-blue1 regular d-block'>
                                        <i className="fa-solid fa-envelope"></i> ventas@hielosecodelbajio.com.mx
                                    </a>
                                    <a href="mailto:contacto@hielosecodelbajio.com.mx" target="_blank" rel="noopener noreferrer" className='fs-5 txt-blue1 regular d-block'>
                                        <i className="fa-solid fa-envelope"></i> contacto@hielosecodelbajio.com.mx
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    )
}