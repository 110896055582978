import { useState, useEffect } from 'react';
import { BrowserRouter as Router, NavLink, Link, Route, Routes } from 'react-router-dom';
import logo from '../assets/images/logowhite.png';
import './Navigation.min.css';
import Footer from './Footer';
import Home from './Home';
import Apicaciones from './Aplicaciones';
import LimpiezaCriogenica from './LimpiezaCriogenica';
import Error404 from './Errr404';
import Recomendaciones from './Recomendaciones';
import Pozos from './Pozos';
import Contacto from './Contacto';

export default function Navigation() {
    const [scrollY, setScrollY] = useState(0)
    const handleNavbar = () => {
        const $navLinks = document.querySelectorAll('.nav-item')
        const $menuToggle = document.getElementById('menuMobile')
        $navLinks.forEach(el => {
            el.addEventListener('click', () => $menuToggle.click())
        })
    }

    useEffect(() => {
        //   console.log('Moviendo el Scroll')
          const detectarScroll = () => {
            setScrollY(window.scrollY)
            const m1 = document.querySelector('.navbar');
            if (window.innerWidth > 991) {
                let top = window.scrollY
                if( top > 3 ) {
                    m1.classList.add('bg-dblue');
                } else {
                    m1.classList.remove('bg-dblue');
                }
            }
        }
          window.addEventListener('scroll', detectarScroll)
          return () => {
            window.removeEventListener('scroll', detectarScroll)
          }
        }, [scrollY])
    useEffect(() => {
        function check_webp_feature(feature, callback) {
            var kTestImages = {
                lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
                lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
                alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
                animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
            };
            var img = new Image();
            img.onload = function () {
                var result = (img.width > 0) && (img.height > 0);
                callback(feature, result);
            };
            img.onerror = function () {
                callback(feature, false);
            };
            img.src = "data:image/webp;base64," + kTestImages[feature];
        }
        check_webp_feature('lossy', function (feature, isSupported) {
            if (!isSupported) {
                document.body.classList.add('webp');
            }
        })
        handleNavbar()
    }, [])

    return (
        <div className="main">
            <div className="Navigation">
                <Router basename='/'>
                    <nav className="navbar fixed-top navbar-expand-xl">
                        <div className="container">
                            <Link to='/' className="navbar-brand"><img src={logo} alt="logo"/></Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu" aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation" id='menuMobile'>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse justify-content-end" id="menu">
                                <ul className="navbar-nav">
                                    <li className="nav-item"><NavLink to='/' className="nav-link fnt-T">Inicio</NavLink></li>
                                    <li className="nav-item"><NavLink to='/aplicaciones' className="nav-link fnt-T">Aplicaciones</NavLink></li>
                                    <li className="nav-item"><NavLink to='/limpieza-criogenica' className="nav-link fnt-T">Limpieza Criogénica</NavLink></li>
                                    <li className="nav-item"><NavLink to='/pozos' className="nav-link fnt-T">Pozos</NavLink></li>
                                    <li className="nav-item"><NavLink to='/recomendaciones' className="nav-link fnt-T">Recomendaciones</NavLink></li>
                                    <li className="nav-item"><NavLink to='/contacto' className="nav-link fnt-T">Contacto</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <Routes>
                        <Route path='/aplicaciones' element={<Apicaciones />} />
                        <Route path='/limpieza-criogenica' element={<LimpiezaCriogenica />} />
                        <Route path='/pozos' element={<Pozos />} />
                        <Route path='/recomendaciones' element={<Recomendaciones />} />
                        <Route path='/contacto' element={<Contacto />} />
                        <Route path='/' element={<Home />} />
                        <Route path='*' element={<Error404 />} />
                    </Routes>
                </Router>
            </div>
            <Footer />
        </div>
    )
}