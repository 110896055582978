import { useEffect } from 'react';
import './Recomendaciones.css';

const iconWarning = `<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 511.99937 445.95902" xml:space="preserve" width="511.99936" height="445.95901" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs17" /><g id="g12" transform="translate(3.6471974e-4,-33.02)" style="fill:#03397d;fill-opacity:1"><g id="g10" style="fill:#03397d;fill-opacity:1"><g id="g8" style="fill:#03397d;fill-opacity:1"><path d="m 506.175,365.576 c -4.095,-10.258 -15.729,-15.255 -25.987,-11.16 -10.258,4.095 -15.255,15.729 -11.16,25.987 5.206,13.043 3.499,27.935 -4.567,39.839 -8.07,11.91 -20.991,18.741 -35.449,18.741 H 83.068 c -15.771,0 -29.776,-8.229 -37.465,-22.015 -7.701,-13.809 -7.339,-30.082 0.968,-43.533 L 219.543,93.382 c 7.876,-12.752 21.519,-20.365 36.497,-20.365 14.978,0 28.62,7.613 36.497,20.365 l 127.927,207.124 c 5.804,9.397 18.128,12.312 27.523,6.505 9.396,-5.803 12.31,-18.126 6.505,-27.523 L 326.564,72.364 C 311.348,47.728 284.984,33.02 256.039,33.02 c -28.945,0 -55.309,14.709 -70.525,39.344 L 12.542,352.418 c -16.036,25.966 -16.734,57.38 -1.869,84.033 14.853,26.63 41.916,42.528 72.395,42.528 h 345.944 c 27.938,0 52.927,-13.233 68.56,-36.303 15.516,-22.898 18.731,-51.721 8.603,-77.1 z" id="path2" style="fill:#03397d;fill-opacity:1" /><path d="m 256.049,399.987 c 11.045,0 19.998,-8.953 19.998,-19.998 0,-11.045 -8.953,-19.998 -19.998,-19.998 h -0.01 c -11.045,0 -19.993,8.953 -19.993,19.998 0,11.045 8.958,19.998 20.003,19.998 z" id="path4" style="fill:#03397d;fill-opacity:1" /><path d="M 276.038,309.995 V 163.008 c 0,-11.045 -8.953,-19.998 -19.998,-19.998 -11.045,0 -19.998,8.953 -19.998,19.998 v 146.987 c 0,11.045 8.953,19.998 19.998,19.998 11.045,0 19.998,-8.953 19.998,-19.998 z" id="path6" style="fill:#03397d;fill-opacity:1" /></g></g></g></svg>`;

export default function Recomendaciones() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="recomendaciones">
                <div className="top">
                    <div className="container py-md-5">
                        <div className="row py-5">
                            <div className="col-12">
                                <div className="icon" dangerouslySetInnerHTML={{__html: iconWarning}} />
                                <h1 className="display-3 txt-white fnt-T bold text-center">RECOMENDACIONES</h1>
                                <p className="txt-white regular text-center">
                                    El hielo seco es un producto utilizado en muchos <br/>
                                    ámbitos porque posee múltiples ventajas y hay que <br/>
                                    manipularlo de manera correcta para que sea seguro.
                                </p>
                            </div>
                            <div className="col-12 py-lg-5 py-3" />
                            <div className="col-lg-4 mb-4">
                                <div className="bar" />
                                <h1 className="fs-1 txt-white fnt-T regular text-start lh-1">
                                    TE RECOMENDAMOS <br/>
                                    <span className="display-4 bold">
                                        TOMAR LAS SIGUIENTES MEDIDAS DE SEGURIDAD:
                                    </span>
                                </h1>
                            </div>
                            <div className="col-lg-8 mb-lg-4">
                                <ul className="listImg fs-5 txt-white regular">
                                    <li>
                                    El uso en piscinas puede causar asfixia, debido a que el CO2 desplaza al oxígeno creando temporalmente una zona de alta concentración de CO2.
                                    </li>
                                    <li>
                                        Debe manejarse en lugares ventilados para evitar riesgo de asfixia por la liberación de anhídrido carbónico.
                                    </li>
                                    <li>
                                        No dejar al alcance de menores de edad, en caso de ingestión accidental deberá trasladarse a la persona inmediatamente a un servicio de urgencias médicas.
                                    </li>
                                    <li>
                                        Para su traslado o almacenamiento, se recomienda utilizar recipientes que resistan bajas temperaturas y que no cierren herméticamente para evitar acumulación de presión; pueden utilizarse hieleras de plástico o unicel.
                                    </li>
                                    <li>
                                        Usar guantes térmicos con el fin de evitar daños en la piel por quemaduras.
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 py-lg-5 py-3" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}