import { useEffect } from 'react';
import './LimpiezaCriogenica.css';

export default function LimpiezaCriogenica() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div id="limcrio">
                <div className="top">
                    <div className="container py-md-5">
                        <div className="row py-5">
                            <div className="col-md-6">
                                <h1 className="display-3 fnt-T bold text-uppercase lh-1 txt-white">
                                    Limpieza Criogénica
                                </h1>
                            </div> {/* <!-- col --> */}
                            <div className="col-md-5 offset-md-1 pt-md-5">
                                <p className="f-titN txt-white">
                                    La limpieza criogénica es uno de los métodos más innovadores en la actualidad, la tecnología de las máquinas que utilizamos ayuda a lanzar el hielo seco a velocidad supersónica lo cual contribuye a retirar la suciedad de los objetos a limpiar, además tiene la cualidad de pasar del estado sólido al gaseoso sin tener que pasar por estado líquido, por lo tanto no deja residuos secundarios.
                                </p>
                            </div> {/* <!-- col --> */}
                            <div className="col-12 py-md-4"></div> {/* <!-- col --> */}
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <small className="f-grapN txt-white">PROCESO DE </small>
                                    <a href="https://iceblast.mx/" target="_blank" rel="noopener noreferrer" className="fs-5 bold f-grapN txt-white">ICEBLAST</a>
                                </div>
                                <h2 className="fs-3 fnt-T bold text-uppercase lh-1 txt-white">
                                    ¿Cómo funciona?
                                </h2>
                            </div> {/* <!-- col --> */}
                            <div className="col-md-5 offset-md-1 pt-md-5">
                                <p className="f-titN txt-white">
                                    Este proceso se divide principalmente en 3 etapas:
                                </p>
                            </div> {/* <!-- col --> */}
                        </div> {/* <!-- row --> */}
                    </div> {/* <!-- cont --> */}
                </div>
                <div className="etapas">
                    <div className="container bg-blue0">
                        <div className="row py-4 justify-content-around">
                            <div className="col-lg-3 col-md-4 mb-md-0 mb-4">
                                <div className="p-xxl-5 p-xl-4">
                                    <picture className="w-100 d-block">
                                        <source srcSet="https://iceblast.mx/images/limpieza_criogenica/ice_blast_limpieza_criogenica_points.webp" type="image/webp"/>
                                        <source srcSet="https://iceblast.mx/images/limpieza_criogenica/ice_blast_limpieza_criogenica_points.png" type="image/png"/>
                                        <img src="https://iceblast.mx/images/limpieza_criogenica/ice_blast_limpieza_criogenica_points.png" alt="points" className="img-fluid points"/>
                                    </picture>
                                    <p className="fs-4 fnt-T bold">
                                        /01
                                    </p>
                                    <div className="bar"></div>
                                    <h3 className="fs-4 fnt-T bold mb-3 txt-blue1">
                                        CHOQUE<br/>CINÉTICO
                                    </h3>
                                    <p className="txt-blue1">
                                        Las partículas de hielo seco se proyectan a través de aire comprimido sobre una superficie, al impactarla, el hielo seco provoca grietas y se infiltra en la capa de suciedad hasta lograr una limpieza profunda.
                                    </p>
                                </div>
                            </div> {/* <!-- col --> */}
                            <div className="col-lg-3 col-md-4 mb-md-0 mb-4">
                                <div className="p-xxl-5 p-xl-4">
                                    <picture className="w-100 d-block">
                                        <source srcSet="https://iceblast.mx/images/limpieza_criogenica/ice_blast_limpieza_criogenica_points.webp" type="image/webp"/>
                                        <source srcSet="https://iceblast.mx/images/limpieza_criogenica/ice_blast_limpieza_criogenica_points.png" type="image/png"/>
                                        <img src="https://iceblast.mx/images/limpieza_criogenica/ice_blast_limpieza_criogenica_points.png" alt="points" className="img-fluid points"/>
                                    </picture>
                                    <p className="fs-4 fnt-T bold">
                                        /02
                                    </p>
                                    <div className="bar"></div>
                                    <h3 className="fs-4 fnt-T bold mb-3">
                                        GOLPE TÉRMICO<br/>
                                        <span className="f-grap">(-78.5/-79°C)</span>
                                    </h3>
                                    <p className="txt-blue1">
                                        El enfriamiento rápido debilita la adherencia entre la suciedad y las capas de la superficie a limpiar; la capa de suciedad es debilitada y se agrieta hasta que finalmente se desprende.
                                    </p>
                                </div>
                            </div> {/* <!-- col --> */}
                            <div className="col-lg-3 col-md-4 mb-md-0">
                                <div className="p-xxl-5 p-xl-4">
                                    <picture className="w-100 d-block">
                                        <source srcSet="https://iceblast.mx/images/limpieza_criogenica/ice_blast_limpieza_criogenica_points.webp" type="image/webp"/>
                                        <source srcSet="https://iceblast.mx/images/limpieza_criogenica/ice_blast_limpieza_criogenica_points.png" type="image/png"/>
                                        <img src="https://iceblast.mx/images/limpieza_criogenica/ice_blast_limpieza_criogenica_points.png" alt="points" className="img-fluid points"/>
                                    </picture>
                                    <p className="fs-4 fnt-T bold">
                                        /03
                                    </p>
                                    <div className="bar"></div>
                                    <h3 className="fs-4 fnt-T bold mb-3">
                                        SUBLIMACIÓN<br/>&nbsp;
                                    </h3>
                                    <p className="txt-blue1">
                                        Cuando el hielo seco entra en contacto con la temperatura más alta, ocurre el proceso de sublimación, es decir, se convierte en gas; lo que implica una gran expansión de volúmen, esta agiliza el efecto térmico y lo reparte rápidamente sobre la superficie a limpiar, hasta que la suciedad es separada totalmente.
                                    </p>
                                </div>
                            </div> {/* <!-- col --> */}
                        </div> {/* <!-- row --> */}
                    </div> {/* <!-- cont --> */}
                    <div className="container py-md-5">
                        <div className="row py-5">
                            <div className="col-12">
                                <p className="fs-1 fnt-T bold text-center text-uppercase txt-blue1">
                                    Así se obtienen superficies <br/>limpias, secas y lo mejor de todo<br/>
                                    <span className="txt-blue2">¡sin daños!</span>
                                </p>
                                <p>
                                    <a href="https://iceblast.mx/" target="_blank" rel="noopener noreferrer" className="btnIr1">Saber más</a>
                                </p>
                            </div> {/* <!-- col --> */}
                        </div> {/* <!-- row --> */}
                    </div> {/* <!-- cont --> */}
                </div>
            </div>
        </>
    )
}