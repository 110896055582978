import './Footer.css';
import logo from '../assets/images/logocolor.png';

export default function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-2 py-lg-5">
                            <a href={process.env.PUBLIC_URL+'/'} >
                                <img src={logo} alt="logo" width="130" className="d-block mx-auto logo" />
                            </a>
                        </div> {/* col */}
                        <div className="col-lg-6 py-lg-5">
                            <ul className="list-inline dir ps-md-5">
                                <li className="list-inline-item">
                                    <div className="bar d-block mb-3"></div>
                                    <p className="txt-white regular m-0 fnt-T">
                                        <b>Salamanca</b><br/>
                                        <a href="tel:+524646487411" target="_blank" rel="noopener noreferrer" className="txt-white regular">
                                            <span className="txt-white">464 648 74 11</span>
                                        </a>
                                        <br/>
                                    </p>
                                </li>
                                <li className="list-inline-item">
                                    <div className="bar d-block mb-3"></div>
                                    <p className="txt-white regular m-0 fnt-T">
                                        <b>San Luis Potosí</b><br/>
                                        <a href="tel:+524446256211" target="_blank" rel="noopener noreferrer" className="txt-white regular">
                                            <span className="txt-white">444 625 62 11</span>
                                        </a>
                                    </p>
                                </li>
                            </ul>
                        </div> {/* col */}
                        <div className="col-lg-4 py-lg-5">
                            <ul className="list-inline ps-md-5">
                                <li className="list-inline-item">
                                    <p className="txt-white regular m-0">
                                        <b>CORREOS</b><br/>
                                        <a href="mailto:ventas@hielosecodelbajio.com.mx" target="_blank" rel="noopener noreferrer" className="txt-white regular">
                                            <span className="txt-white">ventas@hielosecodelbajio.com.mx</span>
                                        </a>
                                        <br/>
                                        <a href="mailto:contacto@hielosecodelbajio.com.mx" target="_blank" rel="noopener noreferrer" className="txt-white regular">
                                            <span className="txt-white">contacto@hielosecodelbajio.com.mx</span>
                                        </a>
                                    </p>
                                </li>
                                <li className="list-inline-item">
                                    <p className="txt-white regular m-0 ms-md-3">
                                        <b>SÍGUENOS</b><br/>
                                        <a href="https://www.facebook.com/hielosecodelbajio" target="_blank" rel="noopener noreferrer" className="txt-white regular">
                                            <i className="fab fa-facebook-square txt-white"></i>
                                        </a>
                                    </p>
                                </li>
                            </ul>
                        </div> {/* col */}
                    </div> {/* row */}
                </div> {/* cont */}
            </footer>
        </>
    )
}