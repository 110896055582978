import axios from 'axios';
import React, { useState } from 'react';
import './Form.min.css';

export default function Form() {
    const [myFormAxios, setMyFormAxios] = useState({}),
    url = process.env.PUBLIC_URL + '/contact_form.php'
    const handleChangeAxios = (e) => {
        setMyFormAxios({
            ...myFormAxios,
            [e.target.name]: e.target.value
        })
    }
    const handleSendData = (e) => {
        e.preventDefault()
        let options = {
            method: 'post',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(myFormAxios)
        }
        axios(options)
        .then(response => {
            alert('Mensaje enviado. Nos pondremos en contacto contigo en breve.')
            window.location.reload()
        })
        .catch(error => {
            let message = error.response.statusText || 'No se pudo enviar el mensaje.'
            console.log(`Error ${error.response.status}: ${message}`)
            alert(`Error ${error.response.status}: ${message}`)
        })
    }
    return (
        <>
            <div className="contactForm">
                <form id="form" onSubmit={e => handleSendData(e)} method='POST'>
                    <input type="text" id="name" name="name" placeholder="Nombre" value={myFormAxios.name} required onChange={handleChangeAxios} />
                    <input type="email" id="email" name="email" placeholder="Correo electrónico" value={myFormAxios.email} required onChange={handleChangeAxios} />
                    <input type="tel" id="phone" name="phone" placeholder="Teléfono" value={myFormAxios.phone} required onChange={handleChangeAxios} />
                    <input type="text" id="city" name="city" placeholder="Ciudad" value={myFormAxios.city} required onChange={handleChangeAxios} />
                    <textarea rows="3" id="message" name="message" placeholder="Mensaje" value={myFormAxios.message} required onChange={handleChangeAxios} />
                    <input type="submit" id="submit" name="submit" value="ENVIAR" />
                </form>
            </div>
        </>
    )
}
