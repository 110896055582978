export default function Error404() {
    return (
        <div className="bg-blue1">
            <div className="container">
                <div className="row align-items-center" style={{height: 'calc(100vh - 204px)'}}>
                    <div className="col-12">
                        <h1 className="text-center txt-white bold fnt-T">
                            ¡Oops!... Contenido no disponible
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}