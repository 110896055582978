import GoogleMap from 'google-map-react';
import { useEffect, useState } from 'react';
import Credentials from './Credentials';

const locationPoints = [
    {tit: 'Salamanca', lat: 20.5868886, lng: -101.1888359}
]
// { tit: 'León', lat: 21.1421846, lng: -101.6883266 },
const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();
    places.forEach((place, i) => {
        bounds.extend(new maps.LatLng(
            place.lat,
            place.lng,
        ));
    });
    return bounds;
}
const apiIsLoaded = (map, maps, places) => {
    var infowindow = new maps.InfoWindow();
    places.forEach((place, i) => {
        let markers = new maps.Marker({
            position: { lat: place.lat, lng: place.lng },
            map,
            title: place.tit
        });
        infowindow.setContent(place.tit);
        // infowindow.open(map, markers);
        maps.event.addListener(markers, 'click', ((marker, i) => {
            return () => {
                infowindow.setContent(place.tit);
                infowindow.open(map, marker);
            }
        })(markers, i));
    });
    const bounds = getMapBounds(map, maps, places);
    bindResizeListener(map, maps, bounds);
    map.fitBounds(bounds);
}
const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
        maps.event.addDomListener(window, 'resize', () => {
            map.fitBounds(bounds);
        });
    });
}
const defaultProps = {
    center: {lat: 20.5868886, lng: -101.1888359},
    zoom: 11 // two locations type 9
}
const mapOptions = (maps) => {
    return {
        mapTypeId: maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        draggable: true,
        navigationControl: true,
        panControl: true,
        zoomControl: true,
        zoomControlOptions: {
            position: maps.ControlPosition.RIGHT_CENTER,
        },
        mapTypeControl: false,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.TOP_CENTER,
        },
        scaleControl: true,
        streetViewControl: true,
        overviewMapControl: true,
        rotateControl: true
    }
}

export default function Map() {
    const [places, setPlaces] = useState([])
    
    useEffect(() => setPlaces(locationPoints), [])

    return (
        <div className="map">
            <GoogleMap
            bootstrapURLKeys={{ key: Credentials.apiKey }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
            options={mapOptions}>
                {places.forEach((place, i) => (
                    <div key={i} text={place.tit} lat={place.lat} lng={place.lng} />
                ))}
            </GoogleMap>
        </div>
    )
}
